import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styles from './SidePanel.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface ICreateSidePanel {
  children: React.ReactNode;
  handleClosePanel: () => void;
  isOpenPanel: boolean;
  title: string;
  createAction: () => void;
  description?: string;
  isNoBackdrop: boolean;
}
export const CreateSidePanel = ({
  children,
  handleClosePanel,
  isOpenPanel,
  title,
  createAction,
  description,
  isNoBackdrop,
}: ICreateSidePanel) => {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: clsx(isNoBackdrop && 'none') } },
      }}
      onClose={handleClosePanel}
      open={isOpenPanel}
      anchor={isMobile ? 'bottom' : 'right'}
      variant="temporary"
    >
      <div className={styles.header}>
        <div className={styles['title-container']}>
          <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
            {title}
          </CustomTypography>
          <Button
            onClick={createAction}
            variant="custom"
            className={clsx('text-14', styles['create-button'])}
            color="secondary"
            startIcon={<AddIcon />}
          >
            Создать
          </Button>
        </div>
        <IconButton className={styles['close-button']} onClick={handleClosePanel}>
          <CloseIcon />
        </IconButton>
      </div>

      {description && (
        <CustomTypography className={clsx('text-15', styles['description-wrapper'])} color="grey">
          {description}
        </CustomTypography>
      )}
      {children}
    </Drawer>
  );
};
