import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styles from './SidePanel.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IBaseSidePanel {
  children: React.ReactNode;
  handleClosePanel: () => void;
  isOpenPanel: boolean;
  title: string;
  description?: string;
  isNoBackdrop: boolean;
}
export const BaseSidePanel = ({
  children,
  handleClosePanel,
  isOpenPanel,
  title,
  description,
  isNoBackdrop,
}: IBaseSidePanel) => {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: clsx(isNoBackdrop && 'none') } },
      }}
      onClose={handleClosePanel}
      open={isOpenPanel}
      anchor={isMobile ? 'bottom' : 'right'}
      variant="temporary"
    >
      <div className={styles.header}>
        <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
          {title}
        </CustomTypography>
        <IconButton className={styles['close-button']} onClick={handleClosePanel}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles['description-wrapper']}>
        {description && (
          <CustomTypography className={clsx('text-15')} color="grey">
            {description}
          </CustomTypography>
        )}
      </div>
      {children}
    </Drawer>
  );
};
