import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './EditProviders.module.css';
import { ReactComponent as AddIcon } from '../icons/Add.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDown.svg';
import { ReactComponent as CheckMarkIcon } from '../icons/CheckMark.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { ReactComponent as EmptyListIcon } from '../icons/EmptyList.svg';
import { ReactComponent as PhoneIcon } from '../icons/Phone.svg';
import { ChooseProvider } from './ChooseProvider';
import {
  useActivateProvidersMutation,
  useDeleteProviderMutation,
  useGetProvidersQuery,
  useDeactivateProvidersMutation,
  MiscProviderType,
  TOauthProvider,
  TMiscProvider,
  EGetProviderAction,
  TEsiaParams,
} from '../redux/services/provider';
import { EditProvider } from './EditProvider';
import { BACKEND_URL, CLIENT_ID } from '../constants';
import { getProviderTitleByType } from '../helpers';
import {
  clientApi,
  useUpdateApplicationMutation,
  useGetApplicationByIdQuery,
} from '../redux/services/client';
import { EditLdapProvider } from './EditLdapProvider';
import { EditEthereumProvider } from './EditEthereumProvider';
import { CustomCheckbox } from './custom/CustomCheckbox';
import { CustomPopoverButton } from './custom/CustomPopoverButton';
import { EditSmsProvider } from './EditSmsProvider';
import { Edit1CProvider } from './Edit1CProvider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { EditIdmProvider } from './EditIdmProvider';
import { useParams } from 'react-router-dom-v5-compat';
import { EditKloudProvider } from './EditKloudProvider';
import { TProviderItem } from './applications/EditApplicationHeader';
import { ProviderScope, useGetRulesProvidersQuery } from '../redux/services/settings';
import { EditEsiaProvider } from './EditEsiaProvider';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalDelete } from './modal/ModalDelete';
import { CustomTypography } from './custom/CustomTypography';
import { Box, useTheme } from '@mui/material';
import { CreateSidePanel } from './sidePanel/CreateSidePanel';
import { EditQRCodeProvider } from './EditQRCodeProvider';

type TEditProviders = {
  isOpen: boolean;
  close: () => void;
  clearSelectedProvider: () => void;
  selectedProvider?: TProviderItem;
  providerType?: ProviderScope;
};

export const EditProviders: FC<TEditProviders> = ({
  isOpen,
  close,
  clearSelectedProvider,
  selectedProvider,
  providerType = ProviderScope.internal,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const { clientId = '' } = useParams<{ clientId: string }>();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const { data: client, isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId || '' },
    {
      skip: !userId || !clientId,
    },
  );
  const theme = useTheme();
  const selectedClient = client?.client;
  const [providers, setProviders] = useState<(TOauthProvider | TMiscProvider)[]>([]);
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEditLdapFormOpen, setIsEditLdapFormOpen] = useState(false);
  const [isEdit1CFormOpen, setIsEdit1CFormOpen] = useState(false);
  const [isEditIdmFormOpen, setIsEditIdmFormOpen] = useState(false);
  const [isEditEthereumFormOpen, setIsEditEthereumFormOpen] = useState(false);
  const [isEditSmsFormOpen, setIsEditSmsFormOpen] = useState(false);
  const [isEditKloudFormOpen, setIsEditKloudFormOpen] = useState(false);
  const [isEditEsiaFormOpen, setIsEditEsiaFormOpen] = useState(false);
  const [isEditQRCodeFormOpen, setIsEditQRCodeFormOpen] = useState(false);
  const typeVariants = ['Все', 'Публичные', 'Для приложения'];
  const [selectedTypeVariant, setSelectedTypeVariant] = useState(typeVariants[0]);
  const anchorPopover = useRef<HTMLButtonElement>(null);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    onlyActive: false,
    action: EGetProviderAction.change,
  });

  const requiredProviderButton = {
    padding: 0,
    [`& + .${styles['provider-buttons']}`]: {
      marginLeft: '16px',
    },
  };

  const { data: rules = [] } = useGetRulesProvidersQuery();

  const filteredProviders: TProviderItem[] = [];
  providers.forEach((provider) => {
    const rule = rules.find((rule) => rule.type === provider.type);
    if (!rule) return false;
    if (
      providerType === ProviderScope.login &&
      ![ProviderScope.login, ProviderScope.trusted, ProviderScope.otp].some((e) =>
        rule.allowedScopes.includes(e),
      )
    ) {
      return false;
    }
    if (
      providerType === ProviderScope.trusted &&
      ![ProviderScope.trusted].some((e) => rule.allowedScopes.includes(e))
    ) {
      return false;
    }
    if (selectedTypeVariant === typeVariants[1] && !provider.is_public) return false;
    if (selectedTypeVariant === typeVariants[2] && provider.is_public) return false;
    provider.name.toLowerCase().includes(searchValue.toLowerCase());
    filteredProviders.push({
      provider,
      rule,
    });
  });

  const dispatch = useDispatch();
  const [deleteProvider] = useDeleteProviderMutation();
  const [activateProvider] = useActivateProvidersMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [updateApplication, { isLoading: updateApplicationLoading }] =
    useUpdateApplicationMutation();

  useEffect(() => {
    if (appProviders) {
      setProviders(appProviders);
    }
  }, [appProviders]);

  useEffect(() => {
    if (!isEditFormOpen) setProviderToEdit(null);
  }, [isEditFormOpen]);

  useEffect(() => {
    selectedProvider && createProviderSettings(selectedProvider.provider);
  }, [selectedProvider]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const createProviderSettings = async (provider: TOauthProvider | TMiscProvider) => {
    setProviderToEdit(provider);

    switch (provider.type) {
      case MiscProviderType.LDAP:
      case MiscProviderType.ALDPRO:
        return setIsEditLdapFormOpen(true);
      case MiscProviderType._1C:
        return setIsEdit1CFormOpen(true);
      case MiscProviderType.IDM:
        return setIsEditIdmFormOpen(true);
      case MiscProviderType.ETHEREUM:
        return setIsEditEthereumFormOpen(true);
      case MiscProviderType.SMS:
        return setIsEditSmsFormOpen(true);
      case MiscProviderType.KLOUD:
        return setIsEditKloudFormOpen(true);
      case MiscProviderType.ESIA:
        return setIsEditEsiaFormOpen(true);
      case MiscProviderType.QRCODE:
        return setIsEditQRCodeFormOpen(true);
      default:
        setIsEditFormOpen(true);
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    await navigator.clipboard.writeText(JSON.stringify(provider));
  };

  const handleDrawerClose = () => {
    clearSelectedProvider();
    close();
  };

  const handleActivateProvider = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [...ids, provider.id]);

    if (!provider.is_active) {
      await activateProvider({
        body: {
          providers: [
            {
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: [
            {
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    }

    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: clientId, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );

    setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== provider.id));
  };

  const handleActivateAllProviders = async () => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [
      ...ids,
      ...filteredProviders.map((providerItem) => providerItem.provider.id),
    ]);

    if (filteredProviders.some((providerItem) => !providerItem.provider.is_active)) {
      await activateProvider({
        body: {
          providers: filteredProviders?.map((providerItem) => {
            return {
              id: String(providerItem.provider.id),
              client_id: providerItem.provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: filteredProviders?.map((providerItem) => {
            return {
              id: String(providerItem.provider.id),
              client_id: providerItem.provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });

      dispatch(
        clientApi.endpoints.getApplicationById.initiate(
          { client_id: clientId, user_id: userId },
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      );
    }

    setFetchingProviderIds(
      fetchingProviderIds.filter((id) =>
        filteredProviders.find((provider) => provider.provider.id !== id),
      ),
    );
  };

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateApplicationLoading || getApplicationFetching || !selectedClient) return;
    await updateApplication({
      currentClientId: selectedClient.client_id,
      params: {
        required_providers_ids: selectedClient.required_providers_ids.includes(String(provider.id))
          ? selectedClient.required_providers_ids.filter((id) => id !== String(provider.id))
          : [...selectedClient.required_providers_ids, String(provider.id)],
        grant_types: selectedClient?.grant_types,
        registration_access_token: selectedClient?.registration_access_token?.jti,
        client_id: selectedClient?.client_id,
        redirect_uris: selectedClient?.redirect_uris,
        post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris,
        require_signed_request_object: selectedClient?.require_signed_request_object,
        request_uris: selectedClient?.request_uris,
        id_token_signed_response_alg: selectedClient?.id_token_signed_response_alg,
        response_types: selectedClient?.response_types,
        introspection_endpoint_auth_method: selectedClient?.introspection_endpoint_auth_method,
        require_auth_time: selectedClient?.require_auth_time,
        revocation_endpoint_auth_method: selectedClient?.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient?.token_endpoint_auth_method,
        subject_type: selectedClient?.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <CreateSidePanel
        handleClosePanel={handleDrawerClose}
        isOpenPanel={isOpen}
        title={
          providerType === ProviderScope.trusted
            ? 'Настроить провайдеры'
            : 'Настроить способы входа'
        }
        description={
          providerType === ProviderScope.trusted
            ? 'Создайте доверенный провайдер и добавьте его в приложение как способ входа. Добавить можно только один доверенный провайдер.'
            : ''
        }
        createAction={() => setIsCreateFormOpen(true)}
        isNoBackdrop={false}
      >
        <div className={styles.wrapper}>
          {/* <CustomTypography className={clsx('text-15', styles['input-description'])} color="grey">
              Выберите способы входа которые хотите добавить в приложение:
              </CustomTypography>
        
          
           #1213
           <TextField
             value={searchValue}
             onChange={(e) => setSearchValue(e.target.value)}
             className={clsx(styles.search, 'custom')}
             fullWidth
             variant="standard"
             placeholder="Поиск"
             InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
             inputProps={{ className: styles.input }}
           />  */}
          {providerType !== ProviderScope.trusted && (
            <div className={styles['types-wrapper']}>
              <CustomCheckbox
                onClick={handleActivateAllProviders}
                disabled={!!fetchingProviderIds.length}
                className={styles.checkbox}
                checked={
                  filteredProviders.every((provider) => provider.provider.is_active) &&
                  !!filteredProviders.length
                }
              />
              <Button
                ref={anchorPopover}
                className={styles['button-type']}
                onClick={() => setPopoverOpen(true)}
                endIcon={<ArrowDownIcon fill="#B6BAC6" />}
              >
                <CustomTypography className={clsx('text-14')} color="grey">
                  Тип:
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['button-type-variant-text'])}>
                  {selectedTypeVariant}
                </CustomTypography>
              </Button>
            </div>
          )}
          <Popover
            classes={{ paper: styles.popover }}
            open={popoverOpen}
            anchorEl={anchorPopover.current}
            onClose={() => setPopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {typeVariants.map((variant) => (
              <CustomPopoverButton
                key={variant}
                onClick={() => {
                  setSelectedTypeVariant(variant);
                  setPopoverOpen(false);
                }}
                startIcon={
                  <CheckMarkIcon
                    className={clsx(styles['popover-button-icon'], {
                      [styles['hidden-button-icon']]: variant !== selectedTypeVariant,
                    })}
                  />
                }
              >
                {variant}
              </CustomPopoverButton>
            ))}
          </Popover>
          <div style={{ overflow: 'auto', paddingBottom: '40px', height: 'calc(100vh - 166px)' }}>
            {!providers.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptyListIcon />
                <CustomTypography
                  style={{ marginBottom: 8 }}
                  className={clsx('text-17')}
                  color="grey"
                >
                  Список пуст
                </CustomTypography>
                <CustomTypography
                  className={clsx('text-14')}
                  style={{ marginBottom: 16, color: '#B6BAC6' }}
                >
                  Добавьте свои способы входа
                </CustomTypography>
                <Button
                  onClick={() => setIsCreateFormOpen(true)}
                  variant="custom"
                  className={clsx('text-14')}
                  color="secondary"
                  startIcon={<AddIcon />}
                >
                  Создать
                </Button>
              </div>
            )}
            {/* 
            #1213
            {!!providers.length && !filteredProviders.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptySearchIcon />
                <CustomTypography
                  style={{ marginBottom: 8 }}
                  className={clsx('text-17')}
                  color="grey"
                >
                  По вашему запросу ничего не найдено
                </CustomTypography>
              </div>
            )} */}
            {filteredProviders
              .sort((a, b) => {
                if (a.provider.is_active !== b.provider.is_active)
                  return !a.provider.is_active ? 1 : -1;
                return a.provider.client_id === CLIENT_ID ? 1 : -1;
              })
              .map((provider) => {
                const required = selectedClient?.required_providers_ids.includes(
                  String(provider.provider.id),
                );
                return (
                  <div
                    className={clsx(styles.provider, {
                      [styles['checked-provider']]: provider.provider.is_active,
                    })}
                    key={provider.provider.id + provider.provider.type}
                  >
                    {providerType !== ProviderScope.trusted && (
                      <CustomCheckbox
                        onClick={() => {
                          handleActivateProvider(provider.provider);
                        }}
                        checked={provider.provider.is_active}
                        className={styles['provider-checkbox']}
                        disabled={
                          !!fetchingProviderIds.find((id) => id === provider.provider.id) ||
                          (clientId === CLIENT_ID &&
                            provider.provider.type === MiscProviderType.CREDENTIALS)
                        }
                      />
                    )}
                    <Box
                      sx={{
                        '& path:nth-child(2),& path:nth-child(3)': {
                          stroke: theme.palette.custom.main,
                          fill: 'none',
                        },
                      }}
                      style={{
                        backgroundImage: `url(${BACKEND_URL + '/' + provider.provider.avatar})`,
                      }}
                      className={styles['provider-icon-wrapper']}
                    >
                      {!provider.provider.avatar &&
                        (provider.provider.type === 'PHONE' ? <PhoneIcon /> : <IdIcon />)}
                    </Box>
                    <div className={styles['provider-name-wrapper']}>
                      <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                        {provider.provider.name}
                      </CustomTypography>
                      <CustomTypography className={clsx('text-12')} color="grey">
                        {getProviderTitleByType(provider.provider.type)}
                      </CustomTypography>
                    </div>
                    {providerType &&
                      providerType !== ProviderScope.trusted &&
                      provider.rule.requireable && (
                        <>
                          {required ? (
                            <IconWithTooltip
                              iconType="starFilled"
                              action={(e) => {
                                e?.stopPropagation();
                                handleChangeRequiredProviders(provider.provider);
                              }}
                              buttonSx={requiredProviderButton}
                              description="Для входа в приложение требуется наличие
                                  способа входа в профиле пользователя"
                            />
                          ) : (
                            <IconWithTooltip
                              iconType="star"
                              action={(e) => {
                                e?.stopPropagation();
                                handleChangeRequiredProviders(provider.provider);
                              }}
                              buttonSx={requiredProviderButton}
                              description="Для входа в приложение не требуется наличие
                                  способа входа в профиле пользователя"
                            />
                          )}
                        </>
                      )}
                    {(clientId === CLIENT_ID || !provider.provider.is_public) && (
                      <div className={styles['provider-buttons']}>
                        {provider.rule.deletable &&
                          providerType &&
                          provider.rule.allowedScopes.includes(providerType) && (
                            <IconWithTooltip
                              iconType="delete"
                              action={() => {
                                setProviderToDelete(provider.provider);
                                setDeleteModalOpen(true);
                              }}
                              disabled={
                                !!fetchingProviderIds.find((id) => id === provider.provider.id) ||
                                providersFetching
                              }
                            />
                          )}
                        {provider.rule.editable &&
                          providerType &&
                          provider.rule.allowedScopes.includes(providerType) && (
                            <>
                              <IconWithTooltip
                                iconType="edit"
                                action={() => createProviderSettings(provider.provider)}
                              />
                              <IconWithTooltip
                                iconType="copy"
                                placement="bottom-end"
                                action={() => handleCopyProvider(provider.provider)}
                              />
                            </>
                          )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </CreateSidePanel>
      <ChooseProvider
        isOpen={isCreateFormOpen}
        close={() => setIsCreateFormOpen(false)}
        providerType={providerType || ProviderScope.trusted}
        providersList={filteredProviders}
      />
      <EditProvider
        providerToEdit={providerToEdit as TOauthProvider}
        isOpen={isEditFormOpen}
        close={() => {
          setIsEditFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditLdapProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditLdapFormOpen}
        close={() => {
          setIsEditLdapFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <Edit1CProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEdit1CFormOpen}
        close={() => {
          setIsEdit1CFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditIdmProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditIdmFormOpen}
        close={() => {
          setIsEditIdmFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditQRCodeProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditQRCodeFormOpen}
        close={() => {
          setIsEditQRCodeFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditEsiaProvider
        providerToEdit={
          providerToEdit as TMiscProvider & {
            params: TEsiaParams;
            provider_id: string;
            avatar?: File | null;
          }
        }
        isOpen={isEditEsiaFormOpen}
        close={() => {
          setIsEditEsiaFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditEthereumProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditEthereumFormOpen}
        close={() => {
          setIsEditEthereumFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditSmsProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditSmsFormOpen}
        close={() => {
          setIsEditSmsFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditKloudProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditKloudFormOpen}
        close={() => {
          setIsEditKloudFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />

      <ModalDelete
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDeleteProvider}
        disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
        title="Удалить способ входа"
        mainMessage={[
          'Способ входа будет удален. Если способ входа используется в приложении, пользователи не смогут войти в него, используя данный способ входа.',
        ]}
      />
    </>
  );
};
