import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { CustomTypography } from '../custom/CustomTypography';
import { PROJECT_NAME } from 'src/constants';
import { CustomRadioButton } from '../CustomRadioButton';
import { CustomButton } from '../custom/CustomButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import styles from './Modal.module.css';
import clsx from 'clsx';
import { getImageURL } from 'src/helpers';
import { TUser } from 'src/redux/services/client';
import { useGetSettingsQuery } from 'src/redux/services/settings';
import { Roles } from 'src/enums';

interface IChangePermissionsModal {
  isOpen: boolean;
  setIsOpen: () => void;
  userToActions: Partial<TUser> | undefined;
  disableButtons: boolean;
  radioButtonSelected: any;
  setSelectedPermission: (role: Roles.ADMIN | Roles.OWNER | Roles.USER | null) => void;
  handleSaveRole: () => void;
}

export const ChangePermissionsModal = ({
  isOpen,
  setIsOpen,
  userToActions,
  disableButtons,
  radioButtonSelected,
  setSelectedPermission,
  handleSaveRole,
}: IChangePermissionsModal) => {
  const { data: dataSettings } = useGetSettingsQuery();

  return (
    <Modal open={isOpen} onClose={setIsOpen}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            Изменить права{' '}
          </CustomTypography>
          <IconButton onClick={setIsOpen} style={{ marginLeft: 'auto', padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')}>
          Выберите права в {PROJECT_NAME} для пользователя:
        </CustomTypography>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
          <Avatar src={getImageURL(userToActions?.picture)} className={styles.avatar}>
            {!userToActions?.picture && (
              <CustomTypography className={clsx('text-14')}>
                {userToActions?.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </CustomTypography>
            )}
          </Avatar>
          <CustomTypography className={clsx('text-14')}>{userToActions?.nickname}</CustomTypography>
        </div>
        <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-15-medium')}>
          Уровень полномочий
        </CustomTypography>
        {!dataSettings?.allow_everyone_to_create_applications && (
          <>
            <CustomRadioButton
              label="Участник"
              checked={radioButtonSelected(Roles.USER)}
              onClick={() => {
                setSelectedPermission(Roles.USER);
              }}
            />
            <CustomTypography
              style={{ marginLeft: 40, marginBottom: 16 }}
              className={clsx('text-12')}
              color="grey"
            >
              Имеет доступ в приложение и может просматривать профиль приложения
            </CustomTypography>
          </>
        )}
        <CustomRadioButton
          label="Администратор личного кабинета"
          checked={radioButtonSelected(Roles.ADMIN)}
          onClick={() => {
            setSelectedPermission(Roles.ADMIN);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 32 }}
          className={clsx('text-12')}
          color="grey"
        >
          Может создавать свои приложения, управлять полномочиями участников своих приложений,
          просматривать персональные данные участников своих приложений
        </CustomTypography>
        <CustomRadioButton
          label="Владелец личного кабинета"
          checked={radioButtonSelected(Roles.OWNER)}
          onClick={() => {
            setSelectedPermission(Roles.OWNER);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 32 }}
          className={clsx('text-12')}
          color="grey"
        >
          Имеет полный контроль над всеми пользователями и приложениями
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="custom" color="secondary" onClick={setIsOpen}>
            Отмена
          </Button>
          <CustomButton
            disabled={disableButtons}
            loading={disableButtons}
            onClick={handleSaveRole}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            Сохранить
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};
