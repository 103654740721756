import React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './Modal.module.css';
import { BACKEND_URL, PROJECT_NAME } from 'src/constants';
import { TClient, TUser } from 'src/redux/services/client';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomRadioButton } from '../CustomRadioButton';
import { Link } from 'react-router-dom-v5-compat';
import clsx from 'clsx';
import { CustomButton } from '../custom/CustomButton';
import { ReactComponent as AppIcon } from '../../icons/App.svg';

interface IDeleteUserModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleDeleteUser: () => void;
  userToActions: Partial<TUser> | undefined;
  applicationsToDelete: TClient[];
  deleteUsersInfoData:
    | { apps: { client: TClient; onlyEditor: boolean }[]; users_ids: number[] }
    | undefined;
  disableButtons: boolean;
  deleteAppsWithUser: boolean;
  setDeleteAppsWithUser: (isDelete: boolean) => void;
}

export const DeleteUserModal = ({
  isOpen,
  setIsOpen,
  handleDeleteUser,
  userToActions,
  applicationsToDelete,
  deleteUsersInfoData,
  disableButtons,
  deleteAppsWithUser,
  setDeleteAppsWithUser,
}: IDeleteUserModal) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles['modal-container']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('header-2-medium', 'font-golos')}
        >
          Удалить аккаунт {PROJECT_NAME}
        </CustomTypography>
        <div>
          <CustomTypography className={clsx('text-14')}>
            Аккаунт {userToActions?.nickname || userToActions?.login || userToActions?.id} будет
            удален. Данные, связанные с аккаунтом, удалятся навсегда. Пользователь потеряет доступ к
            приложениям, в которых для входа использовал аккаунт {PROJECT_NAME}.
          </CustomTypography>
          {!!deleteUsersInfoData?.apps.length && (
            <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
              Вместе с аккаунтом будут удалены приложения без участников, в которых удаляемый
              пользователь является единственным администратором.
            </CustomTypography>
          )}
          {!!applicationsToDelete.length && (
            <>
              <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
                Выберите, что нужно сделать с приложениями с участниками, в которых удаляемый
                пользователь является единственным администратором:
              </CustomTypography>
              <div style={{ marginTop: 10 }}>
                <CustomRadioButton
                  label="Удалить только аккаунты"
                  className={styles['radio-delete-users']}
                  onClick={() => setDeleteAppsWithUser(false)}
                  checked={!deleteAppsWithUser}
                />
                <CustomRadioButton
                  label="Удалить аккаунты и все приложения, которыми они управляют"
                  className={styles['radio-delete-users']}
                  onClick={() => setDeleteAppsWithUser(true)}
                  checked={deleteAppsWithUser}
                />
              </div>
            </>
          )}
        </div>
        {!!applicationsToDelete.length && (
          <div className={styles['apps-to-delete']}>
            {applicationsToDelete.map((app) => (
              <div key={app.client_id} className={styles['app-to-delete']}>
                <div
                  style={{
                    backgroundImage: `url(${BACKEND_URL + '/' + app.avatar})`,
                  }}
                  className={styles['app-icon']}
                >
                  {!app.avatar && <AppIcon fill="#ced0d9" />}
                </div>
                <Link className={styles['app-link']} to={`/applications/${app.client_id}`}>
                  {app.name}
                </Link>
              </div>
            ))}
          </div>
        )}
        <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            onClick={() => setIsOpen(false)}
            variant="custom"
            color="secondary"
          >
            Отмена
          </Button>
          <CustomButton
            loading={disableButtons}
            disabled={disableButtons}
            onClick={handleDeleteUser}
            color="error"
            style={{ marginLeft: 24 }}
          >
            Удалить аккаунт
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};
