import React, { useState } from 'react';
import styles from './ProfileScopes.module.css';
import { ApplicationsTable } from '../applications/ApplicationsTable';
import { ApplicationRightPanel } from '../applications/ApplicationRightPanel';
import { CustomSearchInput } from '../custom/CustomSearchInput';
import {
  TApplication,
  useLazyGetApplicationsCountByScopesQuery,
  useLazyGetApplicationsCountQuery,
  useLazyGetClientsByScopesQuery,
} from 'src/redux/services/client';
import { useLazyGetAllApplicationsCountQuery } from 'src/redux/services/owner';
import { RootState } from '../../redux/rootReducer';
import { useSelector } from 'react-redux';

export const ProfileScopes = () => {
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [getApplicationsCount, { isLoading: getApplicationsCountLoading }] =
    useLazyGetApplicationsCountQuery();
  const [getAllApplicationsCount, { isLoading: getAllApplicationsCountLoading }] =
    useLazyGetAllApplicationsCountQuery();
  const [getApplicationsByScopes, { isFetching: getApplicationsByScopesFetching }] =
    useLazyGetClientsByScopesQuery();
  const [getApplicationCountByScopes, { isLoading: getApplicationCountByScopesLoading }] =
    useLazyGetApplicationsCountByScopesQuery();
  const [clientsCountLoadingStarted, setClientsCountLoadingStarted] = useState(true);
  const [applications, setApplications] = useState<(TApplication | undefined)[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const variant = 'scopes';

  const onSearch = async (searchString: string) => {
    setClientsCountLoadingStarted(true);

    const { data } = await getApplicationCountByScopes({
      userId: userId || '',
      searchString,
    });

    const appCount = data;

    setClientsCountLoadingStarted(false);
    if (appCount !== undefined) gridApplications(appCount);
  };

  const gridApplications = (appCount: number) => {
    setApplications(new Array(appCount).fill(undefined));
  };

  return (
    <div className={styles['wrapper-scopes']}>
      <div className={styles['search-wrapper']}>
        <CustomSearchInput onSearch={onSearch} searchParams={{}} setSearchValue={setSearchValue} />
      </div>
      <div className={styles['tab-container']}>
        <ApplicationsTable
          variant={variant}
          applilcationsFromSearch={applications}
          searchString={searchValue}
        />
        <ApplicationRightPanel variant={variant} />
      </div>
    </div>
  );
};
