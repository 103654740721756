import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import styles from './Modal.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomButton } from '../custom/CustomButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { TUser } from 'src/redux/services/client';
import clsx from 'clsx';

interface IDelereUserSessionsModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  disableButtons: boolean;
  deleteUsersSessionsLoading: boolean;
  userToActions: Partial<TUser> | undefined;
  handleDeleteUsersSessions: () => void;
}

const DeleteUserSessionsModal = ({
  isOpen,
  setIsOpen,
  disableButtons,
  deleteUsersSessionsLoading,
  userToActions,
  handleDeleteUsersSessions,
}: IDelereUserSessionsModal) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            Завершить сеансы
          </CustomTypography>
          <IconButton
            disabled={disableButtons}
            onClick={() => setIsOpen(false)}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
          Завершить сеансы {userToActions?.nickname} во всех приложениях на всех устройствах?
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            variant="custom"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Отмена
          </Button>
          <CustomButton
            loading={deleteUsersSessionsLoading}
            disabled={deleteUsersSessionsLoading}
            style={{ marginLeft: 24 }}
            onClick={handleDeleteUsersSessions}
          >
            Завершить
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserSessionsModal;
