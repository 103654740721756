import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import styles from './Modal.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomButton } from '../custom/CustomButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { PROJECT_NAME } from 'src/constants';
import { TUser } from 'src/redux/services/client';
import { clsx } from 'clsx';

interface IBlockUserModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleBlockUser: () => void;
  haveActiveUsers: boolean;
  disableButtons: boolean;
  usersToBlockCount?: number;
}

export const BlockUserModal = ({
  isOpen,
  setIsOpen,
  handleBlockUser,
  haveActiveUsers,
  disableButtons,
  usersToBlockCount,
}: IBlockUserModal) => {
  console.log('we', usersToBlockCount);
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {haveActiveUsers ? 'Заблокировать ' : 'Разблокировать '}
            {usersToBlockCount && usersToBlockCount !== 1
              ? `пользователей (${usersToBlockCount})`
              : 'пользователя'}
          </CustomTypography>
          <IconButton
            disabled={disableButtons}
            onClick={() => setIsOpen(false)}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
          {haveActiveUsers
            ? usersToBlockCount && usersToBlockCount > 1
              ? `Пользователи потеряют доступ к приложениям, в которых для входа использовали аккаунт
              ${PROJECT_NAME}. Данные, связанные с аккаунтами, будут сохранены.`
              : `Пользователь потеряет доступ к приложениям, в которых
              для входа использовал аккаунт ${PROJECT_NAME}. Данные, связанные
              с аккаунтом, будут сохранены.`
            : usersToBlockCount && usersToBlockCount > 1
            ? `Пользователи будут разблокированы и снова получат доступ к аккаунтам ${PROJECT_NAME}.`
            : `Пользователь будет разблокирован и снова получит доступ к аккаунту ${PROJECT_NAME}.`}
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            variant="custom"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Отмена
          </Button>
          <CustomButton
            loading={disableButtons}
            disabled={disableButtons}
            style={{ marginLeft: 24 }}
            onClick={handleBlockUser}
          >
            {haveActiveUsers ? 'Заблокировать' : 'Разблокировать'}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};
