import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './UserEventLog.module.css';
import clsx from 'clsx';
import { debounce } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as EmptySearchIcon } from '../../icons/EmptySearch.svg';
import { ReactComponent as RefreshIcon } from '../../icons/Refresh.svg';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  TLogger,
  useLazyGetUserEntriesCountQuery,
  useLazyGetUserActivityQuery,
} from '../../redux/services/logger';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import ruLocale from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { getImageURL } from '../../helpers';
import { CLIENT_ID, LOGO_URL } from '../../constants';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomSearchInput } from '../custom/CustomSearchInput';
registerLocale('ru', ruLocale);

type TUserEventLogProps = {
  userId?: string;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

const UserEventLogComponent: FC<TUserEventLogProps> = ({ userId }) => {
  const navigate = useNavigate();
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const loadMoreRowsRef = useRef<null | (() => Promise<void>)>(null);
  const [getUserActivity, { isFetching: getUserActivityFetching }] = useLazyGetUserActivityQuery();
  const [getUserEntriesCount, { isFetching: getUserEntriesCountFetching }] =
    useLazyGetUserEntriesCountQuery();
  const [events, setEvents] = useState<(Partial<TLogger> | undefined)[]>([]);
  const rowCount = events.length;
  const isRowLoaded = ({ index }: { index: number }) => !!events[index];
  const [lastStartIndex, setLastStartIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState<string | undefined>('');
  const [endDate, setEndDate] = useState<string | undefined>('');
  const [isRequestFinished, setIsRequestFinished] = useState(false);

  const onSearch = async (searchString: string) => {
    if (userId) {
      const { data: eventsCount } = await getUserEntriesCount({
        user_id: userId,
        log_type: 'user',
        search_string: searchString,
        start_date: startDate || '',
        end_date: endDate || '',
      });
      if (eventsCount !== undefined) {
        setEvents(new Array(Number(eventsCount)).fill(undefined));
      }
    }
  };

  const setEventsOnStart = async () => {
    if (userId) {
      const { data: eventsCount } = await getUserEntriesCount({
        user_id: userId,
        log_type: 'user',
        search_string: searchValue,
        start_date: startDate || '',
        end_date: endDate || '',
      });
      setEvents(new Array(Number(eventsCount)).fill(undefined));
    }
  };

  useEffect(() => {
    setEventsOnStart();
    return () => setEvents([]);
  }, [userId, startDate, endDate]);

  useEffect(() => {
    if (events.every((event) => event === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [events]);

  useEffect(() => {
    if (!getUserActivityFetching) {
      loadMoreRowsRef.current?.();
      loadMoreRowsRef.current = null;
    }
  }, [getUserActivityFetching]);

  useEffect(() => {
    if (!getUserEntriesCountFetching && !isRequestFinished) {
      const timer = setTimeout(() => {
        setIsRequestFinished(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [getUserEntriesCountFetching, isRequestFinished]);

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
    withoutLastId,
    ignoreLogLoading,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
    withoutLastId?: boolean;
    ignoreLogLoading?: boolean;
  }) => {
    if (!userId) return;
    if (!getUserActivityFetching || ignoreLogLoading) {
      const { data } = await getUserActivity({
        user_id: userId,
        log_type: 'user',
        sort_by: 'date',
        sort_direction: 'desc',
        number_of_records: String(stopIndex - startIndex + 1),
        number_of_skip: String(startIndex),
        last_record_id: withoutLastId
          ? ''
          : startIndex < lastStartIndex
          ? String(events?.[stopIndex + 1]?.id || '')
          : String(events?.[startIndex - 1]?.id || ''),
        start_date: startDate || '',
        end_date: endDate || '',
        search_string: searchString === undefined ? searchValue : searchString,
      });

      setEvents((events) =>
        events.map((event, index) => {
          if (index < startIndex || index > stopIndex) return event;
          return data?.[index - startIndex];
        }),
      );
      setLastStartIndex(startIndex);
    } else {
      loadMoreRowsRef.current = () =>
        loadMoreRows({ startIndex, stopIndex, searchString, ignoreLogLoading: true });
    }
  };

  const debouncedLoadMoreRows = debounce(loadMoreRows, 100);
  const debounceRefreshLog = useCallback(debounce(setEventsOnStart, 50), [
    userId,
    searchValue,
    startDate,
    endDate,
  ]);

  const handleRefreshButtonClick = () => {
    setIsRequestFinished(false);
    debounceRefreshLog();
  };

  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    style: CSSProperties;
  }) => {
    const event = events[index];
    const avatar = event?.client_id === CLIENT_ID ? LOGO_URL : getImageURL(event?.avatar);
    const handleEventClick = () => {
      if (event) navigate(`/profile/activity/${event?.id}`);
    };

    return (
      <Box key={key} style={style} className={styles.event} onClick={handleEventClick}>
        <div className={styles['event-inner']}>
          {!event ? (
            <Skeleton variant="circular" width={56} height={56} />
          ) : (
            <div
              className={styles.icons}
              style={{
                backgroundImage: `url(${avatar})`,
              }}
            >
              {!event.avatar && event.client_id !== CLIENT_ID && (
                <div className={styles['icon-default']}>
                  {event.name
                    ?.split(' ')
                    .map((name: string) => name[0]?.toUpperCase())
                    .join('')}
                </div>
              )}
            </div>
          )}
          <div className={styles['message-wrapper']}>
            {!event ? (
              <Skeleton variant="text" width="40%" />
            ) : (
              <CustomTypography className={clsx('text-14', styles.message)}>
                Вход в приложение {event.name}
              </CustomTypography>
            )}
            {!event?.date ? (
              <Skeleton variant="text" width="20%" />
            ) : (
              <CustomTypography className={clsx('text-12')} color="grey">
                {format(new Date(event.date), 'y-MM-dd HH:mm:ss')}
              </CustomTypography>
            )}
          </div>
        </div>
      </Box>
    );
  };

  return (
    <Box className={'wrapper'} sx={{ overflow: 'hidden' }}>
      <div className={'content'}>
        <Box className={styles.bar}>
          <CustomSearchInput
            onSearch={onSearch}
            searchParams={{ userId: userId, startDate: startDate, endDate: endDate }}
            setSearchValue={setSearchValue}
          />
          <div className={styles['filter-buttons-container']}>
            <div className={styles['date-range-picker']}>
              <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(newValue) => setStartDate(newValue?.toISOString())}
                maxDate={endDate ? new Date(endDate as string) : new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                strictParsing={true}
                className={styles['date-picker']}
                selectsStart
                startDate={startDate ? new Date(startDate) : null}
                endDate={endDate ? new Date(endDate) : null}
              />
              <DatePicker
                selected={endDate ? new Date(endDate) : null}
                onChange={(newValue) => setEndDate(newValue?.toISOString())}
                minDate={new Date(startDate as string)}
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                className={styles['date-picker']}
                selectsEnd
                startDate={startDate ? new Date(startDate) : null}
                endDate={endDate ? new Date(endDate) : null}
                popperPlacement={'bottom-end'}
              />
            </div>
            <Button
              disabled={getUserEntriesCountFetching}
              onClick={handleRefreshButtonClick}
              className={styles['refresh-button']}
              endIcon={
                <RefreshIcon
                  className={clsx(styles['refresh-icon'], {
                    [styles.rotate]: getUserEntriesCountFetching || !isRequestFinished,
                  })}
                />
              }
              tabIndex={-1}
            />
          </div>
        </Box>
        <Box className={styles.events}>
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isRowLoaded={isRowLoaded}
            loadMoreRows={debouncedLoadMoreRows}
            rowCount={rowCount}
          >
            {({ onRowsRendered, registerChild }) => {
              return (
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <>
                        {!rowCount && !getUserEntriesCountFetching && (
                          <Box className={styles.empty}>
                            <div className={styles['empty-inner']}>
                              <EmptySearchIcon className={styles['empty-icon']} />
                              <CustomTypography
                                className={clsx('text-17-regular', 'font-golos')}
                                color="grey"
                              >
                                По вашему запросу ничего не найдено
                              </CustomTypography>
                            </div>
                          </Box>
                        )}
                        <List
                          height={height}
                          width={width}
                          rowHeight={104}
                          rowCount={rowCount}
                          rowRenderer={rowRenderer}
                          onRowsRendered={onRowsRendered}
                          ref={registerChild}
                        />
                      </>
                    );
                  }}
                </AutoSizer>
              );
            }}
          </InfiniteLoader>
        </Box>
      </div>
    </Box>
  );
};

export const UserEventLog = connect(mapStateToProps)(UserEventLogComponent);
