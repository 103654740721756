import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import styles from './CustomSearchInput.module.css';
import { AutocompleteRenderInputParams, debounce } from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../icons/Close.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import clsx from 'clsx';
import { TFilter } from 'src/redux/userSlice';

interface ICustomSearchInput {
  onSearch: (searchString: string) => Promise<void>;
  setSearchValue: (value: string) => void;
  searchParams: any;
  inputParams?: AutocompleteRenderInputParams;
}

export const CustomSearchInput = ({
  onSearch,
  setSearchValue,
  searchParams,
  inputParams,
}: ICustomSearchInput) => {
  const [localSearchValue, setLocalSearchValue] = useState('');

  const onSearchDebounce = useCallback(debounce(onSearch, 50), [searchParams]);

  useEffect(() => {
    setSearchValue(localSearchValue);
  }, [localSearchValue]);

  return (
    <TextField
      {...inputParams}
      className={clsx(styles.search, 'custom')}
      placeholder="Поиск"
      variant="standard"
      value={localSearchValue}
      onChange={(e) => {
        onSearchDebounce(e.target.value);
        setLocalSearchValue(e.target.value);
      }}
      inputProps={{ ...inputParams?.inputProps, className: styles.input }}
      InputProps={{
        ...inputParams?.InputProps,
        startAdornment: <SearchIcon className={styles['search-icon']} />,
        endAdornment: (
          <IconButton
            onClick={() => {
              onSearchDebounce('');
              setLocalSearchValue('');
            }}
          >
            {localSearchValue && <RemoveIcon />}
          </IconButton>
        ),
      }}
    />
  );
};
